define("bottomline-execution/serializers/bx/document-reference", ["exports", "active-model-adapter"], function (_exports, _activeModelAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _activeModelAdapter.ActiveModelSerializer.extend({
    mapResponse: function mapResponse(documentReferences, modelType, modelId) {
      var _this = this;

      var mappedDocumentReferences = documentReferences.map(function (json) {
        return {
          id: json.file_reference,
          type: 'document-reference',
          attributes: {
            description: json.description,
            documentType: _this._getDocumentType(modelType, json.description),
            fileReference: json.file_reference,
            modelId: modelId
          }
        };
      });
      return {
        data: mappedDocumentReferences
      };
    },
    _getDocumentType: function _getDocumentType(modelType, description) {
      if (modelType == 'tripStop') return 'tripStopReport';

      if (description == 'all_stop_documents') {
        return 'tripReport';
      } else {
        return 'driverReport';
      }
    }
  });

  _exports.default = _default;
});